import axios from 'axios';
export default {


    GetReviews(filter) {
        return axios.get(`/api/Reviews/GetReviews`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                productId: filter.productId,
            }
        })
    },
    AddReview(reviewInfo) {
        return axios.post(`/api/Reviews/AddReview`, reviewInfo)
    },
    GetProductBycatagory(categoryId, productId) {
        return axios.get(`/api/Products/GetProductBycatagory`, {
            params: {
                categoryId: categoryId,
                productId: productId,
                PageNo: 1,
                PageSize: 6,
            }
        });
    },
}