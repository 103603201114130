import axios from 'axios';

export default {
    //-------------------   Security -------------------//
    Login(login) {
        return axios.post('/api/Security/Login', login);
    },

    Logout() {
        return axios.post('/api/Security/Logout');
    },
    CheckLogin() {
        return axios.get('/api/Security/CheckLogin');
    },

}