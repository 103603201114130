import axios from 'axios';
export default {
    GetProducerById(producerId) {
        return axios.get(`/api/Designers/GetProducerById`, {
            params: {
                producerId: producerId
            }
        });
    },
    GetProductsArtwork(producerId) {
        return axios.get(`/api/Designers/GetProductsArtwork`, {
            params: {
                producerId: producerId
            }
        });
    },
    GetArtworkById(artworkId) {
        return axios.get(`/api/Designers/GetArtworkById`, {
            params: {
                artworkId: artworkId
            }
        });
    },

    GetProducers() {
        return axios.get(`/api/Designers/GetProducer`)
    },

    GetArtworkByproducer(producerId) {
        return axios.get(`/api/Designers/GetArtworkByproducer`, {
            params: {
                producerId: producerId
            }
        });
    },


}