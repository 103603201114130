//import axios from 'axios';
import securityService from './SecurityService.js';
import HomeService from './HomeService.js';
import ArtwroksService from './ArtwroksService.js';
import ProducersService from './ProducersService.js';
import ProductsService from './ProductsService.js';
import CustomersService from './CustomersService.js';
import ContentsService from './ContentsService.js';
import ReviewsService from './ReviewsService.js';

export default {

    ...securityService,
    homeService: HomeService,
    artwroksService: ArtwroksService,
    producersService: ProducersService,
    productsService: ProductsService,
    customersService: CustomersService,
    contentsService: ContentsService,
    reviewsService: ReviewsService,


}