export const addProductToCart = ({ commit }, {
    productId,
    productAttributeId,
    productName,
    productPrice,
    productQty,
    productImage,
    productColor,
    color,
    size,
    sizeId,
    SizeTypeId,
    height,
    width
}) => {
    commit('ADD_TO_CART', {
        productId,
        productAttributeId,
        productName,
        productPrice,
        productQty,
        productImage,
        productColor,
        color,
        size,
        sizeId,
        SizeTypeId,
        height,
        width
    })

}
export const deletefromcart = ({ commit }, {
    cart,
    index
}) => {
    commit('DELETE_FROM_CART', {
        cart,
        index,
    })
}