import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('../pages/home/Home.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ('../auth/register.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../auth/login.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () =>
            import ('../auth/profile.vue'),
    },


    // start About
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ('../pages/about/About.vue'),
    },

    {
        path: '/cart',
        name: 'cart',
        component: () =>
            import ('../components/Cart.vue'),
    },

    // start product
    {
        path: '/products',
        name: 'products',
        component: () =>
            import ('../pages/product/index/IndexProduct.vue'),
    },
    {
        path: '/products/:id',
        name: 'product',
        component: () =>
            import ('../pages/product/show/ShowProduct.vue'),
    },

    // start artwork
    {
        path: '/artworks',
        name: 'artworks',
        component: () =>
            import ('../pages/artwork/index/IndexArtwork.vue'),
    },
    {
        path: '/artworks/:id',
        name: 'artwork',
        component: () =>
            import ('../pages/artwork/show/ShowArtwork.vue'),
    },

    // start checkout
    {
        path: '/guest-checkout',
        name: 'guest-checkout',
        component: () =>
            import ('../pages/checkout/guestCheckOut.vue'),
    },
    {
        path: '/user-checkout',
        name: 'user-checkout',
        component: () =>
            import ('../pages/checkout/userCheckOut.vue'),
    },

    // start producer
    {
        path: '/producers',
        name: 'producers',
        component: () =>
            import ('../pages/producer/index/IndexProducer.vue'),
    },
    {
        path: '/producers/:id',
        name: 'producer',
        component: () =>
            import ('../pages/producer/show/ShowProducer.vue'),
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router