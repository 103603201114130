<template>
  <div>
    <button
      @click="toggle_menu()"
      id="overlay"
      class="hidden fixed inset-0 w-full h-full bg-primary-black z-30"
      style="opacity: 0.3;"
    ></button>

    <button
      @click="toggle_search()"
      id="overlaySearch"
      class="hidden fixed inset-0 w-full h-full bg-primary-black z-30"
      style="opacity: 0.3;"
    ></button>

    <nav class="bg-primary-black md:bg-surface-white pb-3 pt-4 w-full">
      <div class="container flex items-center justify-between px-4 sm:px-0">
        <div class="w-2/12 lg:w-1/12 xl:w-1/5">
          <a href="/">
            <img src="../assets/logo.svg" alt="" class="hidden md:block" />
            <img src="../assets/logo-white.svg" alt="" class="md:hidden" />
          </a>
        </div>
        <div
          class="flex items-center justify-between w-10/12 lg:w-11/12 xl:w-4/5"
        >
          <div class="hidden w-full md:flex items-center md:w-10/12">
            <div
              class="w-full lg:w-1/3 xl:w-1/2 flex justify-center lg:justify-end relative"
            >
              <div class="w-full px-2 lg:px-6">
                <label for="search" class="sr-only">Search</label>
                <div class="relative">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <svg
                      class="h-5 w-5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18C13.115 18 14.5924 17.411 15.7291 16.4362L19.1465 19.8535L19.8536 19.1464L16.4362 15.7291C17.4111 14.5923 18 13.1149 18 11.5C18 7.91015 15.0899 5 11.5 5ZM6 11.5C6 8.46243 8.46243 6 11.5 6C14.5376 6 17 8.46243 17 11.5C17 14.5376 14.5376 17 11.5 17C8.46243 17 6 14.5376 6 11.5Z"
                        fill="#9E9E9E"
                      />
                    </svg>
                  </div>

                  <div
                    v-if="closeSearchMenu"
                    class="absolute inset-y-0 right-0 pr-3 flex justify-center items-center"
                  >
                    <button
                      @click="canselSearch"
                      class="cursor-pointer rounded-full focus:outline-none"
                    >
                      <svg
                        class="h-5 w-5"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 9L9 15"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 9L15 15"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <input
                    v-model="searchFor"
                    @keyup="search()"
                    id="search"
                    name="search"
                    autocomplete="off"
                    class="block w-full px-10 py-2 rounded-full leading-5 bg-surface-grey focus:outline-none focus:bg-white sm:text-sm"
                    placeholder="Search for Artworks, Apparels, Designers"
                    type="text"
                  />
                </div>
              </div>
              <div
                id="searchMenu"
                v-if="searchMenu"
                class="w-full absolute mt-12 px-2 lg:px-12 z-40"
              >
                <div class="shadow bg-surface-white">
                  <div v-if="resultOfCategories != 0" class="">
                    <p class="bg-grey-100 py-2 px-2">Categories</p>

                    <div class="px-3 py-2">
                      <a
                        :href="
                          $router.resolve({
                            name: 'products',
                            query: { catagory_id: category.id },
                          }).href
                        "
                        class="block py-1"
                        :key="index"
                        v-for="(category, index) in resultOfCategories"
                      >
                        {{ category.name }}
                      </a>
                    </div>
                  </div>

                  <div v-if="resultOfProducts != 0" class="">
                    <p class="bg-grey-100 py-2 px-2">Products</p>

                    <div class="px-3 py-2">
                      <a
                        :href="
                          $router.resolve({
                            name: 'product',
                            params: { id: products.id },
                          }).href
                        "
                        class="block py-1"
                        :key="index"
                        v-for="(products, index) in resultOfProducts"
                      >
                        {{ products.name }}
                      </a>
                    </div>
                  </div>

                  <div v-if="resultOfArtworks != 0" class="">
                    <p class="bg-grey-100 py-2 px-2">Artworks</p>

                    <div class="px-3 py-2">
                      <a
                        :href="
                          $router.resolve({
                            name: 'artwork',
                            params: { id: artwork.id },
                          }).href
                        "
                        class="block py-1"
                        :key="index"
                        v-for="(artwork, index) in resultOfArtworks"
                      >
                        {{ artwork.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden lg:flex">
              <router-link
                :to="{ name: 'home' }"
                class="mx-6 text-base font-medium tracking-wider text-grey-700 hover:text-grey-900 transform duration-300 hover:underline"
              >
                Discover
              </router-link>

              <router-link
                :to="{ name: 'about' }"
                class="mx-6 text-base font-medium tracking-wider text-grey-700 hover:text-grey-900 transform duration-300 hover:underline"
              >
                About
              </router-link>
              <router-link
                :to="{ name: 'producers' }"
                class="mx-6 text-base font-medium tracking-wider text-grey-700 hover:text-grey-900 transform duration-300 hover:underline"
              >
                Designers
              </router-link>
              <router-link
                :to="{ name: 'products' }"
                class="mx-6 text-base font-medium tracking-wider text-grey-700 hover:text-grey-900 transform duration-300 hover:underline"
              >
                Products
              </router-link>
               <router-link
                :to="{ name: 'register' }"
                class="mx-6 text-base font-medium tracking-wider text-grey-700 hover:text-grey-900 transform duration-300 hover:underline"
              >
                register
              </router-link>
            </div>
          </div>

          <div class="w-full md:w-2/12 flex justify-end space-x-4">
            <button @click="toggle_search" class="md:hidden">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.375 6.25C9.88769 6.25 6.25 9.88769 6.25 14.375C6.25 18.8623 9.88769 22.5 14.375 22.5C16.3937 22.5 18.2405 21.7638 19.6614 20.5452L23.9331 24.8169L24.817 23.933L20.5453 19.6613C21.7638 18.2404 22.5 16.3937 22.5 14.375C22.5 9.88769 18.8623 6.25 14.375 6.25ZM7.5 14.375C7.5 10.578 10.578 7.5 14.375 7.5C18.172 7.5 21.25 10.578 21.25 14.375C21.25 18.172 18.172 21.25 14.375 21.25C10.578 21.25 7.5 18.172 7.5 14.375Z"
                  fill="white"
                />
              </svg>
            </button>
            <button class="flex items-center group focus:outline-none">
              <!-- 
                                v-if 
                                guest-checkout
                                user-checkout
                             -->
              <router-link :to="{ name: 'user-checkout' }" class="flex items-center relative">
                <span class="text-surface-white -top-1 -left-1.5 text-xs bg-primary-black w-4 h-4 rounded-full absolute flex justify-center items-center">{{ cartItemCount }}</span>

                <img
                  src="../assets/shopping-bag.svg"
                  alt=""
                  class="group-hover:animate-bounce transform duration-1000 hidden md:block"
                />
                <img
                  src="../assets/shopping-bag-white.svg"
                  alt=""
                  class="group-hover:animate-bounce transform duration-1000 md:hidden"
                />
                <span
                  class="ml-2 text-sm font-bold text-grey-900 hidden lg:block tracking-wider hover:underline"
                  >SHOPPING BAG
                </span>

              </router-link>

            </button>
            <button
              id="menuToggler"
              class="menu focus:outline-none lg:hidden"
              @click="toggle_menu"
              aria-label="Main Menu"
            >
              <span class="hidden md:block">
                <svg
                  class="m-0 p-0 w-8 md:w-12 h-8 md:h-12 "
                  viewBox="0 0 110 110"
                >
                  <path
                    class="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path class="line line2" d="M 20,50 H 80" />
                  <path
                    class="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </span>

              <span class="md:hidden">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.25 21.25H23.75V22.5H6.25V21.25Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.25 15H23.75V16.25H6.25V15Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.25 8.75H23.75V10H6.25V8.75Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </nav>

    <div id="menu" class="hidden bg-surface-grey w-full fixed top-14 z-50">
      <ul class="container py-4">
        <li class="my-4">
          <router-link
            :to="{ name: 'home' }"
            class="text-base font-medium tracking-wider text-grey-900 hover:text-grey-900 hover:underline"
          >
            Discover
          </router-link>
        </li>

        <li class="my-4">
          <router-link
            :to="{ name: 'about' }"
            class="text-base font-medium tracking-wider text-grey-900 hover:text-grey-900 hover:underline"
          >
            About
          </router-link>
        </li>

        <li class="my-4">
          <router-link
            :to="{ name: 'producers' }"
            class="text-base font-medium tracking-wider text-grey-900 hover:text-grey-900 hover:underline"
          >
            Designers
          </router-link>
        </li>

        <li class="my-4">
          <router-link
            :to="{ name: 'products' }"
            class="text-base font-medium tracking-wider text-grey-900 hover:text-grey-900 hover:underline"
          >
            Products
          </router-link>
        </li>  
              <li class="my-4">
          <router-link
            :to="{ name: 'register' }"
            class="text-base font-medium tracking-wider text-grey-900 hover:text-grey-900 hover:underline"
          >
            register
          </router-link>
        </li>
      </ul>
    </div>

    <div
      id="searchMobile"
      class="hidden bg-surface-grey w-full fixed top-14 z-50"
    >
      <div class="container py-4">
        <div class="w-full  relative">
          <div class="w-full px-2 lg:px-6">
            <label for="searchMo" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18C13.115 18 14.5924 17.411 15.7291 16.4362L19.1465 19.8535L19.8536 19.1464L16.4362 15.7291C17.4111 14.5923 18 13.1149 18 11.5C18 7.91015 15.0899 5 11.5 5ZM6 11.5C6 8.46243 8.46243 6 11.5 6C14.5376 6 17 8.46243 17 11.5C17 14.5376 14.5376 17 11.5 17C8.46243 17 6 14.5376 6 11.5Z"
                    fill="#9E9E9E"
                  />
                </svg>
              </div>

              <div
                v-if="closeSearchMenu"
                class="absolute inset-y-0 right-0 pr-3 flex justify-center items-center"
              >
                <button
                  @click="canselSearch"
                  class="cursor-pointer rounded-full focus:outline-none"
                >
                  <svg
                    class="h-5 w-5"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 9L9 15"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 9L15 15"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <input
                v-model="searchFor"
                @keyup="search()"
                id="searchMo"
                name="search"
                autocomplete="off"
                class="block w-full px-10 py-2 rounded-full leading-5 bg-surface-grey focus:outline-none focus:bg-white sm:text-sm"
                placeholder="Search for Artworks, Apparels, Designers"
                type="text"
              />
            </div>
          </div>
          <div id="searchMenu" v-if="searchMenu" class="w-full mt-4 px-2 z-40">
            <div class="">
              <div v-if="resultOfCategories != 0" class="">
                <p class=" py-2 px-2 font-bold">Categories</p>

                <div class="px-3 py-2">
                  <a
                    :href="
                      $router.resolve({
                        name: 'products',
                        query: { catagory_id: category.id },
                      }).href
                    "
                    class="block py-1"
                    :key="index"
                    v-for="(category, index) in resultOfCategories"
                  >
                    {{ category.name }}
                  </a>
                </div>
              </div>

              <div v-if="resultOfProducts != 0" class="">
                <p class=" py-2 px-2 font-bold">Products</p>

                <div class="px-3 py-2">
                  <a
                    :href="
                      $router.resolve({
                        name: 'product',
                        params: { id: products.id },
                      }).href
                    "
                    class="block py-1"
                    :key="index"
                    v-for="(products, index) in resultOfProducts"
                  >
                    {{ products.name }}
                  </a>
                </div>
              </div>

              <div v-if="resultOfArtworks != 0" class="">
                <p class=" py-2 px-2 font-bold">Artworks</p>

                <div class="px-3 py-2">
                  <a
                    :href="
                      $router.resolve({
                        name: 'artwork',
                        params: { id: artwork.id },
                      }).href
                    "
                    class="block py-1"
                    :key="index"
                    v-for="(artwork, index) in resultOfArtworks"
                  >
                    {{ artwork.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
  },
  data() {
    return {
       searchMenu: false,
      closeSearchMenu: false,

      searchFor: "",

      resultOfSearch: "",

      resultOfCategories: {},

      resultOfProducts: {},

      resultOfArtworks: {},
    };
  },
  methods: {
    search() {
      this.closeSearchMenu = true;

      if (this.searchFor == "") {
        this.searchMenu = false;
        this.closeSearchMenu = false;
        return;
      }

      this.searchMenu = true;
      this.$http.homeService
        .GetSearchList(this.searchFor)
        .then((res) => {
          this.resultOfSearch = res.data.result;

          this.resultOfCategories = this.resultOfSearch.categories;
          this.resultOfProducts = this.resultOfSearch.products;
          this.resultOfArtworks = this.resultOfSearch.artworks;

          if (
            this.resultOfCategories == 0 &&
            this.resultOfProducts == 0 &&
            this.resultOfArtworks == 0
          ) {
            this.searchMenu = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    canselSearch() {
      this.searchFor = "";
      this.searchMenu = false;
      this.closeSearchMenu = false;
    },

    toggle_menu() {
      document.querySelector("#overlay").classList.toggle("hidden");
      document.body.classList.toggle("overflow-y-hidden");
      document.getElementById("menu").classList.toggle("hidden");
    },

    toggle_search() {
      document.querySelector("#overlaySearch").classList.toggle("hidden");
      document.body.classList.toggle("overflow-y-hidden");
      document.getElementById("searchMobile").classList.toggle("hidden");
    },
  },
};
</script>



<style scoped>
.router-link-active{
  font-weight: 700;
  color: #000000;
}
</style>