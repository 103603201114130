<template>
<footer class="mt-28 w-full">
        <section class="bg-surface-grey px-4 sm:px-0">
            <div class="max-w-xs sm:max-w-screen-sm lg:px-6 lg:max-w-screen-lg xl:px-0 mx-auto grid grid-cols-2 sm:flex items-start justify-between pt-10 pb-7">
                <nav class="">
                    <div class="text-lg font-bold text-grey-900">STORE</div>
                    <ul>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#" class="hover:underline">Discover</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">About</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Designers</a>
                        </li>
                    </ul>
                </nav>

                <nav class="">
                    <div class="text-lg font-bold text-grey-900">BUY</div>
                    <ul>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Apparels</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Phone Cases</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Prints</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Home Decors</a>
                        </li>
                    </ul>
                </nav>

                <nav class="mt-8 sm:mt-0">
                    <div class="text-lg font-bold text-grey-900">HROOF</div>
                    <ul>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">About</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Services</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Work</a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#">Careers</a>
                        </li>
                    </ul>
                </nav>

                <nav class="mt-8 sm:mt-0">
                    <div class="text-lg font-bold text-grey-900">CONNECT</div>
                    <ul>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#" class="flex items-center">
                                <span class="mr-1">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1667 2.91667C14.1667 2.68667 13.9792 2.5 13.75 2.5H11.6667C9.365 2.5 7.5 4.17917 7.5 6.25V8.5H5.41667C5.18667 8.5 5 8.68667 5 8.91667V11.0833C5 11.3133 5.18667 11.5 5.41667 11.5H7.5V17.0833C7.5 17.3133 7.68667 17.5 7.91667 17.5H10.4167C10.6458 17.5 10.8333 17.3133 10.8333 17.0833V11.5H13.0158C13.2033 11.5 13.3675 11.375 13.4183 11.195L14.0192 9.02833C14.0933 8.76333 13.8933 8.5 13.6183 8.5H10.8333V6.25C10.8333 5.83583 11.2058 5.5 11.6667 5.5H13.75C13.9792 5.5 14.1667 5.31333 14.1667 5.08333V2.91667Z" fill="#9E9E9E"/>
                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="10" height="16">
                                        <path d="M14.1667 2.91667C14.1667 2.68667 13.9792 2.5 13.75 2.5H11.6667C9.365 2.5 7.5 4.17917 7.5 6.25V8.5H5.41667C5.18667 8.5 5 8.68667 5 8.91667V11.0833C5 11.3133 5.18667 11.5 5.41667 11.5H7.5V17.0833C7.5 17.3133 7.68667 17.5 7.91667 17.5H10.4167C10.6458 17.5 10.8333 17.3133 10.8333 17.0833V11.5H13.0158C13.2033 11.5 13.3675 11.375 13.4183 11.195L14.0192 9.02833C14.0933 8.76333 13.8933 8.5 13.6183 8.5H10.8333V6.25C10.8333 5.83583 11.2058 5.5 11.6667 5.5H13.75C13.9792 5.5 14.1667 5.31333 14.1667 5.08333V2.91667Z" fill="white"/>
                                        </mask>
                                        <g mask="url(#mask0)">
                                        </g>
                                    </svg>
                                </span> Facebook
                            </a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#" class="flex items-center">
                                <span class="mr-1">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73078 16.6668C12.7691 16.6668 16.2624 11.9785 16.2624 7.52932C16.2624 7.40682 17.1208 6.67015 17.4883 5.13432C17.5633 4.82265 17.2599 4.57681 16.9741 4.70765C16.2408 5.04432 15.4966 4.77765 15.1733 4.39432C13.9299 3.03848 11.8508 2.97348 10.5283 4.24848C9.67578 5.07098 9.31411 6.29598 9.57828 7.46515C6.78578 7.66432 4.86828 6.34098 3.29161 4.52681C3.08828 4.29098 2.71078 4.41515 2.66828 4.72681C2.43494 6.46182 2.36161 10.6801 6.54161 13.1026C5.81578 14.146 4.39494 14.7618 2.81328 15.026C2.46328 15.0852 2.37994 15.5702 2.69994 15.7268C3.95328 16.3435 5.33078 16.666 6.73078 16.6643" fill="#9E9E9E"/>
                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="14">
                                        <path d="M6.73078 16.6668C12.7691 16.6668 16.2624 11.9785 16.2624 7.52932C16.2624 7.40682 17.1208 6.67015 17.4883 5.13432C17.5633 4.82265 17.2599 4.57681 16.9741 4.70765C16.2408 5.04432 15.4966 4.77765 15.1733 4.39432C13.9299 3.03848 11.8508 2.97348 10.5283 4.24848C9.67578 5.07098 9.31411 6.29598 9.57828 7.46515C6.78578 7.66432 4.86828 6.34098 3.29161 4.52681C3.08828 4.29098 2.71078 4.41515 2.66828 4.72681C2.43494 6.46182 2.36161 10.6801 6.54161 13.1026C5.81578 14.146 4.39494 14.7618 2.81328 15.026C2.46328 15.0852 2.37994 15.5702 2.69994 15.7268C3.95328 16.3435 5.33078 16.666 6.73078 16.6643" fill="white"/>
                                        </mask>
                                        <g mask="url(#mask0)">
                                        </g>
                                    </svg>
                                </span> Twitter
                            </a>
                        </li>
                        <li class="text-sm text-grey-600 mt-5 lg:mt-8 hover:text-grey-900 transform duration-300">
                            <a href="#" class="flex items-center">
                                <span class="mr-1">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.50006 4.375C2.50006 3.34 3.34006 2.5 4.37506 2.5C5.41006 2.5 6.25006 3.34 6.25006 4.375C6.25006 5.41 5.41006 6.25 4.37506 6.25C3.34006 6.25 2.50006 5.41 2.50006 4.375ZM12.6253 7.00034C9.93283 7.00034 7.7495 9.17034 7.7495 11.8453V16.7495C7.7495 17.1637 8.08617 17.5003 8.50033 17.5003H10.2495C10.6637 17.5003 11.0003 17.1637 11.0003 16.7495V11.8453C11.0003 10.8987 11.8203 10.142 12.7937 10.2395C13.6387 10.3237 14.2495 11.0962 14.2495 11.9453V16.7495C14.2495 17.1637 14.5862 17.5003 15.0003 17.5003H16.7495C17.1637 17.5003 17.5003 17.1637 17.5003 16.7495V11.8453C17.5003 9.17034 15.317 7.00034 12.6253 7.00034ZM5.49966 7.74985H3.2505C2.83633 7.74985 2.49966 8.08568 2.49966 8.49985V16.7498C2.49966 17.164 2.83633 17.4998 3.2505 17.4998H5.49966C5.91383 17.4998 6.24967 17.164 6.24967 16.7498V8.49985C6.24967 8.08568 5.91383 7.74985 5.49966 7.74985Z" fill="#9E9E9E"/>
                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.50006 4.375C2.50006 3.34 3.34006 2.5 4.37506 2.5C5.41006 2.5 6.25006 3.34 6.25006 4.375C6.25006 5.41 5.41006 6.25 4.37506 6.25C3.34006 6.25 2.50006 5.41 2.50006 4.375ZM12.6253 7.00034C9.93283 7.00034 7.7495 9.17034 7.7495 11.8453V16.7495C7.7495 17.1637 8.08617 17.5003 8.50033 17.5003H10.2495C10.6637 17.5003 11.0003 17.1637 11.0003 16.7495V11.8453C11.0003 10.8987 11.8203 10.142 12.7937 10.2395C13.6387 10.3237 14.2495 11.0962 14.2495 11.9453V16.7495C14.2495 17.1637 14.5862 17.5003 15.0003 17.5003H16.7495C17.1637 17.5003 17.5003 17.1637 17.5003 16.7495V11.8453C17.5003 9.17034 15.317 7.00034 12.6253 7.00034ZM5.49966 7.74985H3.2505C2.83633 7.74985 2.49966 8.08568 2.49966 8.49985V16.7498C2.49966 17.164 2.83633 17.4998 3.2505 17.4998H5.49966C5.91383 17.4998 6.24967 17.164 6.24967 16.7498V8.49985C6.24967 8.08568 5.91383 7.74985 5.49966 7.74985Z" fill="white"/>
                                        </mask>
                                        <g mask="url(#mask0)">
                                        </g>
                                    </svg>
                                </span> LinkedIn
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>

        <section class="bg-primary-black px-4 sm:px-0">
            <div class="container">
                <div class=" flex flex-col lg:flex-row items-center justify-between pt-6 lg:pt-4 pb-8">
                    <div class="lg:w-2/12">
                        <a href="#">
                            <img src="../assets/logo-white.svg" alt="logo">
                        </a>
                    </div>
                    <div class="text-center lg:hidden my-5">
                        <a href="#" class="text-grey-300 hover:text-grey-100 transform duration-500 text-xs md:text-sm">
                            Privacy Policy 
                        </a> •
                        <a href="#" class="text-grey-300 hover:text-grey-100 transform duration-500 text-xs md:text-sm">
                            Terms of Service
                        </a>
                    </div>
                    <div class="text-center lg:text-left text-xs md:text-sm text-surface-white lg:w-7/12">
                        © 2020 Hroof Creative Agency, LLC. LG Commerce. All rights reserved.
                    </div>
                    <div class="hidden lg:block lg:w-3/12 text-right">
                        <a href="#" class="text-grey-300 hover:text-grey-100 transform duration-500 text-sm">
                            Privacy Policy • Terms of Service
                        </a>

                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
export default {};
</script>

<style></style>
