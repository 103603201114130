import axios from 'axios';
export default {

    GetBanners(pageNo, pageSize) {
        return axios.get(`/api/Home/GetBanners?pageNo=${pageNo}&pageSize=${pageSize}`)
    },
    GetCategories(pageNo, pageSize) {
        return axios.get(`/api/Home/GetCategories?pageNo=${pageNo}&pageSize=${pageSize}`)
    },
    GetSubCategories(pageNo, pageSize) {
        return axios.get(`/api/Home/GetSubCategories?pageNo=${pageNo}&pageSize=${pageSize}`)
    },
    GetProducts(pageNo, pageSize) {
        return axios.get(`/api/Home/GetProducts?pageNo=${pageNo}&pageSize=${pageSize}`)
    },
    GetArtwroks(pageNo, pageSize, sortBy) {
        return axios.get(`/api/Home/GetArtworks?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}`)
    },

    GetSearchList(searchTerm) {
        return axios.get(`/api/Home/GetSearchList`, {
            params: {
                searchTerm: searchTerm
            }
        });
    },
}