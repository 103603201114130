import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
    components: {
        Navbar,
        Footer,
    },
    created() {
        this.CheckLogin();
    },
    data() {
        return {}
    },
    methods: {
        CheckLogin() {
            this.$http.CheckLogin()
                .then(res => {
                    this.loding = false
                    let data = res.data;
                    if (data.result == true) {  
                         this.$authenticatedUser.userId = data.user.userId;
                        this.$authenticatedUser.fullName = data.user.fullName;
                        this.$authenticatedUser.userName = data.user.userName;
                        this.$authenticatedUser.address = data.user.address;
                        this.$authenticatedUser.city =data.user.city;
                        this.$authenticatedUser.country =data.user.country;
                        this.$authenticatedUser.phoneNo =data.user.phoneNo;
                        // this.$router.go(this.$router.currentRoute)


                        //                        this.state = 2;
                    } else {
                        this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = '';
                        this.$authenticatedUser.userName = '';
                        this.$authenticatedUser.address = '';
                        this.$authenticatedUser.city ='';
                        this.$authenticatedUser.country ='';
                        if (this.$route.meta.Dashboard) {
                            this.$router.replace('/login');
                        }

                        // this.state = 1;

                    }
                })
                .catch(() => {
                    this.loding = false
                    this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = '';
                        this.$authenticatedUser.userName = '';
                        this.$authenticatedUser.address = '';
                        this.$authenticatedUser.city ='';
                        this.$authenticatedUser.country ='';
                    // this.state = 1;
                    this.$router.replace('/login');

                })
        },
        // Logout() {
        //     //this.$blockUI.Start();
        //     //   this.profile = false;
        //     //  this.loding = true;
        //     this.$http.Logout()
        //         .then(res => {
        //             let data = res.data;
        //             if (data.statusCode === 0) {
        //                 this.$authenticatedUser.userId = 0;
        //                 this.$authenticatedUser.fullName = '';
        //                 this.$authenticatedUser.userName = '';
        //                 this.$authenticatedUser.userPermissions = [];
        //                 // this.state = 1;

        //                 this.$router.replace('/login');

        //                 //this.$blockUI.Stop();
        //             } else {
        //                 //this.$blockUI.Stop();
        //             }
        //         })
        //         .catch(() => {
        //             //this.$blockUI.Stop();
        //         })
        // },
    }
}