import Vue from "vue";
import Layout from "./layout/Layout.vue";
import DataService from "./services/DataService.js";
import router from "./router";
import store from "./store/index.js";
import "tailwindcss/tailwind.css";
import "./assets/css/styles.css";
import Axios from "axios";

Axios.defaults.baseURL = "https://hroofstore.ly/";

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$authenticatedUser = {
    userId: 0,
    fullName: null,
    userName: null,
    address: null,
    city: null,
    country: null,
    phoneNo: null,
};
Vue.use(router);
Vue.use(Axios);

new Vue({
    router,
    store,
    render: (h) => h(Layout),
}).$mount("#app");