import axios from 'axios';
export default {

    GetArtworkById(artworkId) {
        return axios.get(`/api/Artworks/GetArtworkById`, {
            params: {
                artworkId: artworkId
            }
        });
    },
    GetProductsArtwork(producerId) {
        return axios.get(`/api/Artworks/GetProductsArtwork`, {
            params: {
                producerId: producerId
            }
        });
    },
    GetArtworks(artworkGenreId, artworkId) {
        return axios.get(`/api/Artworks/GetArtworks`, {
            params: {
                artworkGenreId: artworkGenreId,
                artworkId: artworkId
            }
        });
    },
}