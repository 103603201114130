 export const ADD_TO_CART = (state, {
     productId,
     productAttributeId,
     productName,
     productPrice,
     productQty,
     productImage,
     productColor,
     color,
     size,
     sizeId,
     SizeTypeId,
     height,
     width
 }) => {
     let productInCart = state.cart.find(item => {
         return (item.productId === productId && item.productColor.colorId === productColor.colorId && item.sizeId === sizeId);

     })
     if (productInCart) {
         productInCart.productQty += productQty;
         localStorage.setItem("cart", JSON.stringify(state.cart));

         var addsum = 0

         state.cart.forEach(item => {
             addsum += item.productPrice * item.productQty;
         });
         localStorage.setItem("cartTotalPrice", JSON.stringify(addsum));
         return
     }
     state.cart.push({
         productId,
         productAttributeId,
         productName,
         productPrice,
         productQty,
         productImage,
         productColor,
         color,
         size,
         sizeId,
         SizeTypeId,
         height,
         width
     })
     localStorage.setItem("cart", JSON.stringify(state.cart));
     localStorage.setItem("cartItemCount", JSON.stringify(state.cart.length));
     var sum = 0

     state.cart.forEach(item => {
         sum += item.productPrice * item.productQty;
     });
     localStorage.setItem("cartTotalPrice", JSON.stringify(sum));
 }
 export const DELETE_FROM_CART = (state, {
     cart,
     index

 }) => {
     let cartTotalPrice = 0
     state.cart.splice(index, 1);
     cartTotalPrice = localStorage.getItem("cartTotalPrice");
     cartTotalPrice = cartTotalPrice - (cart.productPrice * cart.productQty)
     localStorage.setItem("cartTotalPrice", JSON.stringify(cartTotalPrice));
     localStorage.setItem("cart", JSON.stringify(state.cart));
     localStorage.setItem("cartItemCount", JSON.stringify(state.cart.length));
 }