export const cartItemCount = (state) => {
    var cartcount
    var local = localStorage.getItem("cartItemCount")
    if (state.cart != null) {
        cartcount = state.cart.length;
    }
    if (local > 0) {
        cartcount = localStorage.getItem("cartItemCount")
        cartcount
    }
    return cartcount
}
export const cartTotalPrice = (state) => {
    let total = 0;
    var local = localStorage.getItem("cartTotalPrice")
    var localcart = JSON.parse(localStorage.getItem("cart"))

    if (state.cart != null) {
        state.cart.forEach(item => {
            total += item.productPrice * item.productQty;
        });
    }
    if (local > 0) {
        localcart.forEach(item => {
            total += item.productPrice * item.productQty;
        });
        total = JSON.parse(localStorage.getItem("cartTotalPrice"))


    }
    return total;
}