import axios from 'axios';
export default {

    GetCategories() {
        return axios.get(`/api/Products/GetCategories`)
    },
    GetFilter() {
        return axios.get(`/api/Lookup/GetFilter`)
    },
    GetProducts(productFilter) {
        return axios.post(`/api/Products/GetProducts`, productFilter);
    },

    GetProductById(productId) {
        return axios.get(`/api/Products/GetProductDetails`, {
            params: {
                productId: productId
            }
        });
    },
    GetProductBycatagory(categoryId, filter) {
        return axios.get(`/api/Products/GetProductBycatagory`, {
            params: {
                categoryId: categoryId,
                PageNo: filter.pageNo,
                PageSize: filter.pageSize,
            }
        });
    },
    AddProductToCart(OrderInfo) {
        return axios.post(`/api/Orders/AddOrder`, OrderInfo)
    },
    SadadValidate(SadadValidate) {
        return axios.post(`/api/Orders/SadadValidate`, SadadValidate)
    },
}